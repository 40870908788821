import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import StaticPage from "../components/static-page"

const ImpressumPage = () => {
  const siteData = useStaticQuery(graphql`
    query {
      contentfulStaticPage(title: { eq: "Impressum" }) {
        title
        content {
          json
        }
      }
    }
  `)

  return (
    <StaticPage title={siteData.contentfulStaticPage.title} content={siteData.contentfulStaticPage.content.json} />
  )
}

export default ImpressumPage
